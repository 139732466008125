export default class DashboardFetchersCore {
    static backupSwitch = $('#dashboard-backup-system-on-off');
    static vueSelect = $('#vue')
    static abortControllers = {}; // Store abort controllers for each key

    static get DEFAULT_RELOAD_INTERVAL() {
        return {
            hours: 0,
            minutes: 3,
            seconds: 30,
        };
    }

    static get DEFAULT_FETCH_INTERVAL() {
        return {
            hours: 0,
            minutes: 2,
            seconds: 30,
        };
    }

    static timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getInterval(interval) {
        return (interval.hours ?? 0) * 3600000 + (interval.minutes ?? 0) * 60000 + (interval.seconds ?? 0) * 1000;
    }

    static setLoader(key, isLoading) {
        const table = this.COUNTER_LISTS[key]?.table;
        if (!table) {
            console.warn(`Table not found for: ${key}`);
            return;
        }

        const elBlock = $(table).closest('.block');
        if (!elBlock.length) {
            console.warn(`Block element not found for table: ${key}`);
            return;
        }

        elBlock.toggleClass('block-mode-loading', isLoading);
    }

    static setLoaders(isLoading) {
        const keys = Object.keys(this.COUNTER_LISTS);
        keys.forEach(key => this.setLoader(key, isLoading));
    }

    static async fetchRemoteItem(key, force = false) {
        const { url, table } = this.COUNTER_LISTS[key];
        const tbody = $(table);

        if (!tbody.length || !url) return;

        return new Promise((resolve, reject) => {
            const controller = new AbortController();
            this.abortControllers[key] = controller;

            $.ajax({
                url: url + (force ? '/force' : ''),
                type: 'GET',
                dataType: 'json',
                success: function (response) {
                    resolve(response.data);
                },
                error: function (error) {
                    const code = error.status;
                    let label = 'Une erreur est survenue lors de la récupération des données.';
                    if (code === 403) {
                        label = 'Vous n\'avez pas accès à cette ressource.';
                    }

                    resolve({
                        'N/A': {
                            url: null, count: null, label: label
                        }
                    });
                }
            });
        });
    }

    static async getItem(dbName, key, timeoutDuration = 6000, force = false) {
        this.setLoader(key, true);

        let isTimeExceeded = true;
        let localItem = { data: {}, timestamp: 0 };
        const reloadInterval = this.COUNTER_LISTS[key]?.reloadInterval || this.DEFAULT_RELOAD_INTERVAL;

        if (dbName) {
            localItem = await this.getLocalItem(dbName, key);
            isTimeExceeded = Date.now() - localItem.timestamp > this.getInterval(reloadInterval);
        }

        if (force || isTimeExceeded) {
            const fetchPromise = this.fetchRemoteItem(key);

            await Promise.race([fetchPromise, this.timeout(timeoutDuration)]);

            fetchPromise.then(data => {
                data = data ?? {};

                if (dbName) this.setLocalItem(dbName, key, data);
                this.refreshTable(dbName, key, data);
            }).catch(error => {
                console.error('Error fetching data:', error);
            });

            return fetchPromise;
        } else {
            this.refreshTable(dbName, key, localItem.data);
            return Promise.resolve();
        }
    }

    static async refreshTable(dbName, key, data = null) {
        const { table, last_issue_column } = this.COUNTER_LISTS[key];
        const tbody = $(table);
        tbody.empty();

        if (dbName) data = data ?? (await this.getLocalItem(dbName, key)).data ?? {};

        Object.entries(data).forEach(([id, item]) => {
            const { url, label, count, count_last_issue } = item;
            let row =
                `<tr 
                    ${url && count !== null ? `
                    class="cursor-pointer" 
                    data-href="${url}"
                    title="${url}"` : `
                    class="bg-warning"
                    title="Une erreur est survenue"
                    `}
                >
                    <td class="w-50">
                        <span class="font-w600">
                            ${label}
                        </span>
                    </td>`;

            if (count != null) {
                row +=
                    `<td class="text-center ${!last_issue_column ? 'w-50' : ''}">
                    <span class="badge badge-pill bg-primary font-w600 text-white">
                        ${count}
                    </span>
                </td>`;

                if (last_issue_column)
                    row +=
                        `<td class="text-center">
                        ${count_last_issue ?
                            `<span class="badge badge-pill bg-primary font-w600 text-white">
                            ${count_last_issue}
                        </span>` :
                            ''}
                    </td>`;
            }

            row += '</tr>';

            tbody.append(row);
        });

        setTimeout(() => {
            this.setLoader(key, false);
        }, 250);
    }

    static async fetchAllItemsSequentially(dbName, force) {
        if (this.backupSwitch) this.backupSwitch.prop('disabled', true);
        if (this.vueSelect) this.vueSelect.prop('disabled', true);
        if (this.version == 2) dbName = null;

        if (!dbName) force = true;

        const keys = Object.keys(this.COUNTER_LISTS);

        this.setLoaders(true);

        const fetchPromises = [];

        try {
            for (const key of keys) {
                const fetchPromise = this.getItem(dbName, key, 2000, force);
                fetchPromises.push(fetchPromise);

                await Promise.race([fetchPromise, this.timeout(2000)]);
            }
        } catch (error) {
            console.error('Error fetching all items:', error);
        } finally {
            await Promise.allSettled(fetchPromises);

            if (this.backupSwitch) this.backupSwitch.prop('disabled', false);
            if (this.vueSelect) this.vueSelect.prop('disabled', false);
        }
    }
}
