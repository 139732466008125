import DashboardFetchersCore from "./core";

export class V1 extends DashboardFetchersCore {
    static version = 1;

    static get COUNTER_LISTS() {
        return {
            pre_visites: {
                url: `${process.env.MIX_API_URL}/dashboard/pre_visites`,
                table: '#pre_visites-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                }
            },
            raccordements: {
                url: `${process.env.MIX_API_URL}/dashboard/raccordements`,
                table: '#raccordements-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                },
                last_issue_column: true,
            },
            post_production: {
                url: `${process.env.MIX_API_URL}/dashboard/post_production`,
                table: '#post_production-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                }
            },
            support_be: {
                url: `${process.env.MIX_API_URL}/dashboard/support_be`,
                table: '#support-be-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                }
            },
            ot_prise_contact_raccordement: {
                url: `${process.env.MIX_API_URL}/dashboard/ot_prise_contact_raccordement`,
                table: '#ot_prise_contact_raccordement-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                }
            },
            enquete_de_satisfaction: {
                url: `${process.env.MIX_API_URL}/dashboard/enquete_de_satisfaction`,
                table: '#enquete_de_satisfaction-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                }
            },
            sav_gta: {
                url: `${process.env.MIX_API_URL}/dashboard/sav_gta`,
                table: '#sav_gta-table',
                reloadInterval: {
                    hours: 0,
                    minutes: 1,
                    seconds: 0,
                }
            },
        };
    }

    static indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    static dbVersion = 1;

    static dbs = {};

    static async initializeDB(dbName, dbVersion) {
        if (!dbName || !dbVersion) console.warn('Database name or version not provided, using default values.');

        return new Promise((resolve, reject) => {
            const request = this.indexedDB.open(dbName, dbVersion);

            request.onupgradeneeded = event => {
                const db = event.target.result;
                const keys = Object.keys(this.COUNTER_LISTS);
                keys.forEach(key => {
                    if (!db.objectStoreNames.contains(key)) {
                        db.createObjectStore(key, { keyPath: 'id' });
                    }
                });
            };

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    static async getLocalItem(dbName, key) {
        const id = window.selectedVue?.id;
        if (!id) return { id: null, data: {}, timestamp: 0 };

        const db = await this.dbs[dbName] || await this.dbs['dashboard-data'];
        const transaction = db.transaction([key], 'readonly');
        const store = transaction.objectStore(key);

        return new Promise((resolve, reject) => {
            const request = store.get(id);
            request.onsuccess = () => {
                resolve(request.result || { id, data: {}, timestamp: 0 });
            };
            request.onerror = () => reject(request.error);
        });
    }

    static async setLocalItem(dbName, key, data) {
        if (this.version == 2) return;

        const id = window.selectedVue?.id;
        if (!id) return;

        const db = await this.dbs[dbName] || await this.dbs['dashboard-data'];
        const transaction = db.transaction([key], 'readwrite');
        const store = transaction.objectStore(key);
        const item = { id, data: data, timestamp: Date.now() };
        await store.put(item);
    }

    static async init() {
        this.dbs = {
            'dashboard-data': await this.initializeDB('dashboard-data', this.dbVersion),
            'dashboard-data-backup': await this.initializeDB('dashboard-data-backup', this.dbVersion),
        };
        await V1.fetchAllItemsSequentially(
            window.isModeBackupChecked ? 'dashboard-data-backup' : 'dashboard-data',
            false
        );
    }
}
