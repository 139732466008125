import DashboardFetchersCore from "./core";

export class V2 extends DashboardFetchersCore {
    static version = 2;
    static allowForceReload = false;

    static COUNTER_LISTS = new Proxy({}, {
        set(target, key, value) {
            if (value === null || value === undefined) {
                delete target[key];
                V2.removeTableFromDOM(key);
            } else {
                target[key] = value;
                V2.addTableToDOM(value);
            }
            return true;
        },
        deleteProperty(target, key) {
            V2.removeTableFromDOM(key);
            delete target[key];
            return true;
        },
    });

    static async getViewableLists() {
        const url = `${process.env.MIX_API_URL}/dashboard/v2/lists`;
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                success: function (response) {
                    const { data } = response;

                    Object.keys(V2.COUNTER_LISTS).forEach(key => {
                        if (!data.hasOwnProperty(key)) {
                            delete V2.COUNTER_LISTS[key];
                        }
                    });

                    for (let [key, value] of Object.entries(data)) {
                        if (!V2.COUNTER_LISTS[key]) {
                            V2.COUNTER_LISTS[key] = {
                                ...value,
                                table: `#${key}-table`,
                                key: key,
                            };
                        }
                    }

                    V2.reorderTablesInDOM(Object.keys(data));

                    resolve(response.data);
                },
                error: function (error) {
                    console.error('Error fetching viewable lists:', error);
                    reject(error);
                }
            });
        });
    }

    static addTableToDOM(list) {
        let template =
            `<div class="col-lg-4" data-key="${list.key}">
                <div class="block">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">${list.name}</h3>`;

        if (V2.allowForceReload)
            template += `<button type="button" class="btn-block-option" data-toggle="block-option"
                        data-action="state_toggle" data-refresh="${list.key}">
                        <i class="si si-refresh font-size-sm"></i>`;

        template +=
            `</div>
                    <div class="block-content block-content-full">
                        <table class="table table-hover table-borderless table-vcenter font-size-sm mb-0">
                            <tbody id="${list.key}-table"></tbody>
                        </table>
                    </div>
                </div>
            </div>`;

        $('#dashboard-lists').append(template);
    }

    static removeTableFromDOM(key) {
        const _table = V2.COUNTER_LISTS[key]?.table;
        if (!_table) return;
        const table = $(`#${key}-table`).closest('.col-lg-4');
        if (!table.length) return;
        table.remove();
    }

    static reorderTablesInDOM(keys) {
        const parent = $('#dashboard-lists');
        keys.forEach(key => {
            const item = parent.find(`[data-key="${key}"]`);
            if (item.length) {
                parent.append(item);
            }
        });
    }

    static async init(forceFetch = false) {
        await V2.getViewableLists();
        V2.fetchAllItemsSequentially(
            null,
            forceFetch
        );
    }
}
